<template>
  <router-view/>
</template>

<style>
@font-face {
  font-family: 'Cruinn'; /* Name of the font */
  src: url('@/assets/fonts/Cruinn.ttf') format('truetype'); /* Path to your font file */
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: 'Cruinn', sans-serif;
}
</style>
