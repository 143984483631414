import { createRouter, createWebHistory } from 'vue-router';
import PresetSelector from '@/components/PresetSelector.vue';
import Game from '@/components/GameEngine.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: PresetSelector,
  },
  {
    path: '/game/:presetId',
    name: 'game',
    component: Game,
    props: true,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: '', 
  linkExactActiveClass: '', 
});

export default router;