<template>
  <div class="game-container" @click="handleContainerClick">
    <div class="background" :style="{ backgroundImage: `url(${currentScene.background})` }"></div>
    <div class="character" :style="{ backgroundImage: `url(${currentScene.character})` }"></div>
    
    <div class="choices-container">
      <button v-for="(choice, index) in currentScene.choices" :key="index" @click.stop="selectChoice(choice)">
        {{ choice.text }}
      </button>
    </div>

    <div class="text-window">
      <p v-if="currentScene.characterName">{{ currentScene.characterName }}</p>
      <p class="game_message">{{ currentScene.text }}</p>
    </div>

    <div v-if="isGameOver" class="game-over">
      <p>{{ currentScene.isFinalScene ? 'Вы достигли конца игры!' : 'Игра закончилась' }}</p>
      <button @click="exitGame" class="button_over">Назад</button>
    </div>
  </div>
</template>

<script>
  import colleague_talk from '@/assets/character/colleague/talk.png';
  import colleague_question from '@/assets/character/colleague/question.png';

  import scam_talk from '@/assets/character/scam/talk.png';
  import scam_stupor from '@/assets/character/scam/stupor.png';
  import scam_nervousness from '@/assets/character/scam/nervousness.png';

  import tatyana_angry from '@/assets/character/tatyana/angry.png';
  import tatyana_talk from '@/assets/character/tatyana/talk.png';
  import tatyana_happiness from '@/assets/character/tatyana/happiness.png';

  import ofice_blue from '@/assets/background/ofice_blue.png';
  import ofice_gray from '@/assets/background/ofice_gray.png';
  import ofice_red from '@/assets/background/ofice_red.png';

  const scam_name = "Мошенник";
  const tatyana_name = "Татьяна";
  const colleague_name = "Коллега";
  
  export default {
    props: ['presetId'],
    data() {
      return {
        scenes: this.loadScenes(this.presetId),
        currentSceneIndex: 0,
        isGameOver: false,
      };
    },
    computed: {
      currentScene() {
        return this.scenes[this.currentSceneIndex];
      },
    },
    methods: {
      loadScenes(presetId) {
        if (presetId === '1') {
          return [
            {
              text: "Татьяна сидела за работой в офисе как вдруг раздался звонок.",
              character: null,
              characterName: null,
              background: ofice_gray,
              choices: []
            },
            {
              text: "Привет мам!",
              character: scam_talk,
              characterName: scam_name,
              background: ofice_blue,
              choices: [],
            },
            {
              text: "Привет",
              character: tatyana_talk,
              characterName: tatyana_name,
              background: ofice_gray,
              choices: []
            },
            {
              text: "У меня для тебя отличные новости! Я выиграла два билета в Турцию на работе!",
              character: scam_talk,
              characterName: scam_name,
              background: ofice_blue,
              choices: [],
            },
            {
              text: "Это замечательно! От меня что-нибудь требуется?",
              character: tatyana_happiness,
              characterName: tatyana_name,
              background: ofice_gray,
              choices: [],
            },
            {
              text: "Да, мам. Нужен твой паспорт. Скинь его пожалуйста на этот номер",
              character: scam_talk,
              characterName: scam_name,
              background: ofice_blue,
              choices: []
            },
            {
              text: "А почему именно на этот? Кстати, почему ты с незнакомого какого-то звонишь?",
              character: tatyana_talk,
              characterName: tatyana_name,
              background: ofice_gray,
              choices: []
            },
            {
              text: "Мой телефон разрядился, взяла у коллеги. Ну же мам, это срочно! Иначе билеты отдадут кому-то другому!",
              character: scam_nervousness,
              characterName: scam_name,
              background: ofice_blue,
              choices: [],
            },
            {
              text: "Подошел коллега",
              character: colleague_talk,
              characterName: null,
              background: ofice_gray,
              choices: [],
            },
            {
              text: "Слушай, а с кем ты разговариваешь?",
              character: colleague_talk,
              characterName: colleague_name,
              background: ofice_gray,
              choices: [],
            },
            {
              text: "Да, дочка моя Ленка говорит, что билеты выиграла. Сейчас паспорт ей скину.",
              character: tatyana_talk,
              characterName: tatyana_name,
              background: ofice_gray,
              choices: [],
            },
            {
              text: "А ты точно уверена что это она?",
              character: colleague_question,
              characterName: colleague_name,
              background: ofice_gray,
              choices: [
                { text: "Конечно, какие глупости!", nextScene: 12 },
                { text: "А ведь и правда...", nextScene: 17 },
              ],
            },
            {
              text: "Татьяна посылает фотографию паспорта на номер.",
              character: null,
              characterName: null,
              background: ofice_gray,
              choices: [],
            },
            {
              text: "Спасибо мам! Ура скоро в турцию полетим!",
              character: scam_talk,
              characterName: scam_name,
              background: ofice_red,
              choices: [],
            },
            {
              text: "Да, я так рада!",
              character: tatyana_happiness,
              characterName: tatyana_name,
              background: ofice_gray,
              choices: [],
            },
            {
               text:"Через пару часов Татьяна обнаруживает что на неё было взято 5 микрозаймов.",
               character : tatyana_angry,
               characterName : tatyana_name,
               background :ofice_gray,
               choices :[],
            },
            {
               text:"*Вы были обмануты мошенником, который имитировал голос ваших близких при помощи нейросети.",
               character : scam_talk,
               characterName : null,
               background :ofice_red,
               choices :[],
               isFinalScene :true ,
            },
            {
               text:"Ленусь, а как там Вадик?",
               character : tatyana_talk ,
               characterName : tatyana_name,
               background :ofice_gray,
               choices :[],
            },
            {
               text:"А да всё в порядке",
               character : scam_nervousness ,
               characterName : scam_name,
               background : ofice_blue ,
               choices :[],
            },
            {
               text:"От наркоза уже отошел?",
               character : tatyana_talk ,
               characterName : tatyana_name,
               background :ofice_gray ,
               choices :[],
            },
            {
               text:"Да, уже во всю борщ кушает!",
               character : scam_talk ,
               characterName : scam_name,
               background : ofice_blue ,
               choices :[],
            },
            {
               text:"Борщ?! Лена! Вадик это твой пёс!",
               character : tatyana_angry ,
               characterName : tatyana_name,
               background : ofice_gray,
               choices : [],
            },
            { 
                text:"*В растерянности* Да я же просто пошутила! Так что там с фото? Нас долго ждать не будут!",
                character : scam_stupor,
                characterName : scam_name,
                background : ofice_blue ,
                choices:[
                    {
                      text : "Отправить фото", 
                      nextScene : 12 
                    },
                    {
                      text : "Продолжить разговор", 
                      nextScene : 23
                    },
                ],
            },
            {
                text :"Доча, напомни как называется твоя компания, тётя Ира спрашивает.",
                character : tatyana_talk ,
                characterName : tatyana_name,
                background :ofice_gray ,
                choices :[],
            },
            {
                text :"Ну ты ведь и сама знаешь мам!",
                character : scam_nervousness,
                characterName : scam_name,
                background : ofice_blue ,
                choices :[],
            },
            {
                text :"Запамятовала доча, а что же ты не помнишь как называется собственная компания?",
                character : tatyana_talk ,
                characterName : tatyana_name,
                background :ofice_gray ,
                choices :[],
            },
            {
                text :"Эм... это...",
                character : scam_nervousness ,
                characterName : scam_name,
                background :ofice_blue ,
                choices :[],
            },
            {
                text :"Ага, попалась! Никакая ты не Лена! Я сейчас же позвоню дочери!",
                character : tatyana_angry,
                characterName : tatyana_name,
                background :ofice_gray ,
                choices : [],
            },
            {
               text: "*Вы сохранили свои данные. Мошенник не смог обмануть вас, имитируя голос вашей дочери при помощи нейросети.",
               character: tatyana_happiness,
               characterName : null,
               background: ofice_gray,
               choices: [],
               isFinalScene: true,
             }
          ];
        } // else if (presetId === '3') {
        //  return [
        //    {
        //       text: "*Вы сохранили свои данные. Мошенник не смог обмануть вас, имитируя голос вашей дочери при помощи нейросети.",
        //       character: tatyana_happiness,
        //       characterName : tatyana_name,
        //       background: ofice_gray,
        //       choices: [],
        //       isFinalScene: true,
        //     }
        //  ]
        //}
        return [];
      },
      handleResize() {
        this.isLandscape = window.innerWidth > window.innerHeight;
      },
      handleContainerClick() {
        if (!this.currentScene.choices.length) {
          this.nextScene();
        }
      },
      nextScene() {
        if (this.currentScene.isFinalScene) { 
          this.isGameOver = true;
        } else if (this.currentSceneIndex < this.scenes.length - 1) {
          this.currentSceneIndex++;
        } else {
          this.isGameOver = true;
        }
      },
    
      selectChoice(choice) {
        this.currentSceneIndex = choice.nextScene;
      },
      exitGame() {
        window.location.href = '/';
      },
    },
    beforeUnmount() {
      window.removeEventListener('resize', this.handleResize);
    }
  };
</script>
   
<style scoped>
@font-face {
  font-family: 'Cruinn';
  src: url('@/assets/fonts/Cruinn.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: 'Cruinn', sans-serif;
}

.game-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
}

.choices-container {
  position: absolute;
  bottom: calc(100px + (10%));
  left: 20%;
  right: 20%;
  display: flex;
  justify-content: space-between;
}

.character {
  position: fixed;
  left: 50%;
  bottom: 0; 
  transform: translate(-50%, 5%);
  height: 100%;
  width: 80%; 
  background-size: contain; 
  background-repeat: no-repeat; 
  background-position: center; 
  background-position-y: bottom
}

.text-window {
  position: absolute;
  bottom: 3%; 
  left: 5%;
  right: 5%;
  height: auto; 
  font-size: clamp(14px, 2vw, 22px);
  background-color: rgba(255,255,255,0.7);
  border: 5px solid rgba(0,123,255,0.5); 
  border-radius: 10px;
  padding: 15px;
}

.character_name {
  font-weight: bold; 
  color: #007BFF; 
}

button {
  font-family: 'Cruinn', sans-serif;
  flex-grow:1; 
  background-color: #007BFF; 
  color: white; 
  border:none; 
  border-radius:10px; 
  padding:10px; 
  margin:5px; 
  cursor:pointer; 
}
  
button:hover {
  background-color:#0056b3; 
}

.button_over {
  margin-top:20px;
}
  
.button_over:hover {
  background-color:#0056b3; 
}

.game-over {
  position:absolute;
  top:50%; 
  left:50%; 
  transform:translate(-50%, -50%);
  background-color:rgba(255,255,255,0.9);
  border-radius:10px;
  padding:15px;
}

.game_message {
  text-align:center; 
}
</style>